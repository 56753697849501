import { crypto, Data, Future } from "hyker-crypto";
import verifyAttestation from "./attestation";

const isNode =
  typeof process !== "undefined" &&
  typeof process.release !== "undefined" &&
  process.release.name === "node";

const WS = isNode ? require("ws") : WebSocket;

const connect = (host, issuer) => {
  return new Promise((resolve, reject) => {
    try {
      const queue = [];
      const received = [];

      const receive = ({ message, error }) => {
        // Check if there is something waiting for data
        if (queue.length > 0) {
          if (error) {
            queue.shift().reject(error);
          } else {
            queue.shift().resolve(message);
          }
        } else {
          received.push({ message, error });
        }
      };

      const ws = new WS(`wss://${host}/ws/${issuer}`);
      ws.onopen = (_event) => {
        let closed = false;
        const close = () => {
          if (closed) return;
          closed = true;
          ws.close();
          queue.forEach((future) =>
            future.reject(new Error("Connection closed"))
          );
          queue.length = 0;
          received.length = 0;
        };
        const send = (message) => {
          if (closed) throw new Error("Connection closed");
          ws.send(JSON.stringify(message));
        };
        const receive = async () => {
          if (closed) throw new Error("Connection closed");
          if (received.length > 0) {
            const { error: wsError, message } = received.shift();
            if (wsError) throw wsError;
            const { error, ...response } = JSON.parse(message);
            if (error) throw new Error(error.reason);
            return response;
          }
          const future = new Future();
          queue.push(future);
          const { error, ...response } = JSON.parse(await future.promise);
          if (error) {
            if (error.reason === "invalid_message") {
              throw new Error(error.description);
            }
            throw new Error(error.reason);
          }
          return response;
        };
        resolve({
          isConnecting: () => ws.readyState === WebSocket.CONNECTING,
          isOpen: () => ws.readyState === WebSocket.OPEN,
          isClosing: () => ws.readyState === WebSocket.CLOSING,
          isClosed: () => ws.readyState === WebSocket.CLOSED,
          send,
          receive,
          close,
        });
      };

      ws.onerror = (event) => receive({ error: new Error(event) });
      ws.onmessage = (message) => receive({ message: message.data });
      ws.onerror = (event) => reject(event);
    } catch (error) {
      reject(error);
    }
  });
};

export default async function start(config) {
  const {
    host,
    issuer,
    securityVersion,
    uniqueID,
    signerID,
    productID,
    certificate,
    privateKey,
  } = config;
  const socket = await connect(host, issuer);
  const ecdh = await crypto.generateECDHKeyPair();
  await socket.send({
    public_key: [...(await ecdh.publicKey.export("spki")).getUint8Array()],
  });
  const { public_key } = await socket.receive();
  const serverPublicECDH = await crypto.importPublicECDHKey(
    Data.fromBase64(public_key),
    "spki"
  );
  const sharedKey = await serverPublicECDH.agree(ecdh.privateKey);

  let nextOrder = 0;

  async function encryptSend(command, args = {}) {
    const order = nextOrder;
    nextOrder += 1;

    // Send
    {
      const iv = crypto.random(32);
      const ciphertext_tag = await sharedKey.encrypt(
        Data.fromUTF8(
          JSON.stringify({
            command,
            args,
            order,
          })
        ),
        iv
      );
      const ciphertext = new Data([
        ...ciphertext_tag.getUint8Array().slice(0, ciphertext_tag.length - 16),
      ]);
      const tag = new Data([
        ...ciphertext_tag.getUint8Array().slice(ciphertext_tag.length - 16),
      ]);
      socket.send({
        iv: iv.toBase64(),
        ciphertext: ciphertext.toBase64(),
        tag: tag.toBase64(),
      });
    }

    // Receive
    {
      const { ciphertext, tag, iv } = await socket.receive();
      const message = await sharedKey.decrypt(
        Data.join([Data.fromBase64(ciphertext), Data.fromBase64(tag)]),
        Data.fromBase64(iv)
      );
      const response = JSON.parse(message.toUTF8());
      if (response.command !== command) {
        throw new Error(
          `Unexpected response command ${response.command}, expected ${command}.`
        );
      }
      if (response.order !== order) {
        throw new Error(
          `Unexpected response order ${response.order}, expected ${order}.`
        );
      }
      if (response.error) {
        if (response.error.message) {
          throw new Error(
            `${response.error.reason}: ${response.error.message}`
          );
        } else {
          throw new Error(response.error.reason);
        }
      }
      return response.result;
    }
  }

  const { quote, issuer: attestedIssuer } = await encryptSend("attest");
  await verifyAttestation(Data.fromBase64(quote), {
    securityVersion,
    uniqueID,
    signerID,
    productID,
  });
  if (attestedIssuer !== issuer) {
    throw new Error(
      `Attested issuer ${attestedIssuer} does not match requested issuer ${issuer}`
    );
  }

  if (certificate && privateKey) {
    const importedPrivateKey = await crypto.importPrivateECDSAKey(
      Data.fromBase64(privateKey),
      "pkcs8"
    );
    const signature = [
      ...crypto
        .berEncodeSignature(
          await importedPrivateKey.sign(await sharedKey.export("raw"))
        )
        .getUint8Array(),
    ];
    await encryptSend("verify_certificate", {
      certificate: [...Data.fromBase64(certificate).getUint8Array()],
      signature,
    });
  }

  return {
    isConnecting: () => socket.isConnecting(),
    isOpen: () => socket.isOpen(),
    isClosing: () => socket.isClosing(),
    isClosed: () => socket.isClosed(),
    authenticateSwedishBankId: async () => {
      await encryptSend("start_authentication", {
        provider: {
          type: "unknown_swedish_bank_id",
        },
      });
    },
    authenticateEmail: async (address) => {
      await encryptSend("start_authentication", {
        provider: {
          type: "email",
          address,
        },
      });
    },
    authenticateSms: async (phone_number) => {
      await encryptSend("start_authentication", {
        provider: {
          type: "sms",
          phone_number,
        },
      });
    },
    cancel: async () => {
      await encryptSend("cancel_authentication");
    },
    collect: async (uid) => {
      const { private_identity, token } = await encryptSend("get_identity", {
        uid,
      });
      return {
        identity: private_identity,
        token,
      };
    },
    generate: async ({
      uid,
      emailAddresses,
      phoneNumbers,
      swedishPersonalNumbers,
      samlProviders,
    }) => {
      const { public_identity } = await encryptSend("issue_identity", {
        uid,
        providers: [
          [
            ...(emailAddresses || []).map((address) => ({
              type: "email",
              address,
            })),
            ...(phoneNumbers || []).map((phone_number) => ({
              type: "sms",
              phone_number,
            })),
            ...(swedishPersonalNumbers || []).map(
              (swedish_personal_number) => ({
                type: "swedish_bank_id",
                swedish_personal_number,
              })
            ),
            ...(samlProviders || []).map(({ idProvider, emailAddress }) => ({
              type: "saml",
              id_provider: idProvider,
              address: emailAddress,
            })),
          ],
        ],
      });
      return public_identity;
    },
    getAuthorizationToken: async (branding) => {
      const { token } = await encryptSend("issue_token", {
        type: "authorization",
        branding,
      });
      return token;
    },
    getPrivateToken: async () => {
      const { token } = await encryptSend("issue_token", {
        type: "private",
      });
      return token;
    },
    getPublicToken: async () => {
      const { token } = await encryptSend("issue_token", {
        type: "public",
      });
      return token;
    },
    migrate: async ({
      uid,
      emailAddresses,
      phoneNumbers,
      swedishPersonalNumbers,
    }) => {
      await encryptSend("set_identity_providers", {
        uid,
        providers: [
          [
            ...(emailAddresses || []).map((address) => ({
              type: "email",
              address,
            })),
            ...(phoneNumbers || []).map((phone_number) => ({
              type: "sms",
              phone_number,
            })),
            ...(swedishPersonalNumbers || []).map(
              (swedish_personal_number) => ({
                type: "swedish_bank_id",
                swedish_personal_number,
              })
            ),
          ],
        ],
      });
    },
    reset: async () => {
      return encryptSend("reset_authentication");
    },
    getSession: async () => {
      const { session_id, session_key } = await encryptSend("get_session");
      return { sessionId: session_id, sessionKey: session_key };
    },
    restoreSession: async ({ sessionId, sessionKey }) => {
      return encryptSend("restore_session", {
        session_id: sessionId,
        session_key: sessionKey,
      });
    },
    status: async () => {
      return encryptSend("get_authentication_status");
    },
    unlockEmail: async ({ address, code }) => {
      await encryptSend("unlock_challenge", {
        provider: {
          type: "email",
          address,
        },
        code,
      });
    },
    unlockSms: async ({ phoneNumber, code }) => {
      await encryptSend("unlock_challenge", {
        provider: {
          type: "sms",
          phone_number: phoneNumber,
        },
        code,
      });
    },
    verifyToken: async (token) => {
      return await encryptSend("consume_issuer_token", {
        token,
      });
    },
    close: () => socket.close(),
  };
}
