import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Overlay } from "@blueprintjs/core";
import {
  Viewer,
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
} from "../viewer/Viewer.jsx";
import {
  startDownloadBlobBackground,
  saveBlobToDisk,
  startUpdate,
} from "../transfer/transferSlice.js";
import { selectIdentity, getToken } from "../../app/topSlice.js";
import { appState } from "../../app/appState.js";
import { unWrapNonSerializableValue } from "../../helpers/nonSerializableValues/index.js";
//import { SplashPreview } from "./PreviewBase";
//import PreviewCell from "./PreviewCell";
//import PreviewDocs from "./PreviewDocs";
//import PreviewPdf from "./PreviewPdf";
//import PreviewSlides from "./PreviewSlides";
//import PreviewNull from "./PreviewNull";
import WidgetEsignature from "./WidgetEsignature.js";
import WidgetAttestation from "./WidgetAttestation.js";
import {
  ORIGINAL,
  isPackage,
  toPackage,
  fromPackage,
} from "../esignature/packaging/provider.js";
import {
  normalizeNode,
  packageAndUpdate,
  esignatureTouch,
  logEvent,
  esignatureFinalized,
} from "../esignature/esignatureSlice.js";
import { doFetch } from "../attestation/slice.js";
import appContext from "../../app/appContext.js";
import styles from "./Preview.module.css";

import { PDF_NOT_SUPPORTED } from "../esignature/packaging/provider.js";

import {
  FILE_DOWNLOAD,
  FILE_PREVIEW,
  ESIGNATURE_REQUEST,
  ESIGNATURE_APPROVE,
  ESIGNATURE_REJECT,
  ESIGNATURE_COMPLETED,
  ATTESTATION_REQUEST,
  ATTESTATION_APPROVE,
  ATTESTATION_REJECT,
} from "../../app/permissions.js";

const {
  selectors: { selectGraph, selectTimeSkew },
  actions: { loadNode },
} = appState;

//const VIEWERS = {
//  docx: PreviewDocs,
//  xlsx: PreviewCell,
//  pptx: PreviewSlides,
//  pdf: PreviewPdf,
//};

export const isPreviewableFileType = (node) =>
  isViewableFileExt(getFileExtension(node?.name ?? ""));

export const isSignableFileType = (node) =>
  isSignableFileExt(getFileExtension(node?.name ?? ""));

const WidgetNull = () => null;

export default ({
  dispatch,
  file,
  node,
  permissions,
  active,
  close,
  nodeId,
  zoneId,
  sign,
  blob: preBlob,
  retur,
  //iDidJustSign,
}) => {
  const identity = useSelector(selectIdentity);
  const timeSkew = useSelector(selectTimeSkew);
  const { nodes } = useSelector(selectGraph);
  const [widget, setWidget] = useState(null);
  const [loading, setLoading] = useState(false);
  let [theBlob, setBlob] = useState(undefined);
  const [alreadyPackaged, setAlreadyPackaged] = useState(false);
  const [pdfNotSupported, setPdfNotSupported] = useState(false);
  const { groupId } = useContext(appContext);
  const userId = identity?.id;

  if (preBlob) {
    preBlob = unWrapNonSerializableValue(preBlob);
  }

  theBlob = preBlob || theBlob;

  file = file || node;
  nodeId = nodeId || file?.id;

  let theNode = file;
  /*   let groupId = file?.group?.id; */

  // try to make file updated in realtime
  if (nodeId && nodeId in nodes) {
    theNode = nodes[nodeId];
  }

  // this case is unrecoverable
  if (nodeId && !theNode && !zoneId) {
    console.log("Overlay preview: bad args.");
  }

  theNode = normalizeNode(theNode);

  if (nodeId && theNode && nodeId !== theNode.id) {
    console.log("@ nodeId and node.id mismatch");
    return;
  }

  const hasNode = !!theNode;

  const esign = theNode?.esignature;

  const signed = esign?.status === "signed";

  const completed = esign?.completed;

  const signedButNotComplete = signed && !completed;

  const signable = theNode?.name?.endsWith?.(".pdf");

  const canRequest = permissions?.[ESIGNATURE_REQUEST];
  const canRespond =
    permissions[ESIGNATURE_REJECT] || permissions[ESIGNATURE_APPROVE];

  const instaSign = sign || (esign && (canRequest || canRespond));

  const theWidget = widget || (instaSign && zoneId && WidgetEsignature);

  const esignWidget = theWidget == WidgetEsignature;

  const nullWidget = theWidget == WidgetNull;

  const showSign =
    !esignWidget && zoneId && signable && (canRequest || canRespond);

  const awaitingSigned =
    retur && !esign?.approvals.some((doc) => doc.user === userId);

  ////////////////////// ATTESTATION /////////////////////////

  /*  const attestation = theNode?.attestations; */

  const canAttestionRequest = permissions[ATTESTATION_REQUEST];
  const canAttestionRespond =
    permissions[ATTESTATION_APPROVE] || permissions[ATTESTATION_REJECT];

  /* const instaAttest = attestation && (canAttestionRequest || canAttestionRespond); */

  const attestionWidget = theWidget == WidgetAttestation;

  const showAttestation =
    !attestionWidget && zoneId && (canAttestionRequest || canAttestionRespond);

  useEffect(() => {
    if (!active) {
      setWidget(null);
      setAlreadyPackaged(false);
      setPdfNotSupported(false);
    }
  }, [active]);

  useEffect(() => {
    if (active && nodeId && zoneId && !hasNode) {
      dispatch(loadNode({ nodeId, zoneId }));
    }
  }, [active, nodeId, zoneId, hasNode]);

  useEffect(() => {
    if (
      active &&
      nodeId &&
      hasNode &&
      theBlob === undefined &&
      !awaitingSigned
    ) {
      const onComplete = async (blob) => {
        if (esignWidget && signedButNotComplete) {
          if (!(await isPackage(esign, blob))) {
            const token = await dispatch(getToken());
            blob = await toPackage(esign, blob, theNode.name, token);
            setBlob(blob);
            await dispatch(startUpdate(zoneId, theNode, blob));
          } else {
            setBlob(blob);
          }
          await dispatch(esignatureFinalized(esign.id));
          return;
        }
        setBlob(blob);
      };
      dispatch(
        startDownloadBlobBackground(theNode, FILE_PREVIEW, () => {}, onComplete)
      );
    }
  }, [
    active,
    nodeId,
    zoneId,
    hasNode,
    theBlob === undefined,
    esignWidget,
    awaitingSigned,
  ]); // hasNode or node?.id ?

  useEffect(() => {
    if (attestionWidget && active && groupId && nodeId) {
      dispatch(doFetch(groupId, nodeId, setLoading));
    }
  }, [active, groupId, nodeId, attestionWidget]);

  const onDownload = async () => {
    if (permissions?.[FILE_DOWNLOAD]) {
      if (theBlob) {
        let aBlob = theBlob;
        if (
          //(signedButNotComplete || completed) && // why also completed?
          signedButNotComplete &&
          !(await isPackage(esign, aBlob))
        ) {
          const token = await dispatch(getToken());
          aBlob = await toPackage(esign, aBlob, theNode.name, token);
        }
        await dispatch(logEvent("download", theNode?.id));
        saveBlobToDisk(aBlob, theNode.name);
      }
    }
  };

  const onDownloadOriginal = async () => {
    if (permissions?.[FILE_DOWNLOAD]) {
      if (theBlob) {
        let aBlob = theBlob;
        if (await isPackage(esign, aBlob)) {
          let original;
          try {
            original = (await fromPackage(esign, aBlob))[ORIGINAL];
          } catch (e) {
            console.log(e);
          }
          if (!original) {
            return;
          }
          aBlob = original;
        }
        await dispatch(logEvent("download", theNode?.id));
        saveBlobToDisk(aBlob, theNode.name);
      }
    }
  };

  const onSign = showSign && (() => setWidget(() => WidgetEsignature));
  const onAttest =
    showAttestation && (() => setWidget(() => WidgetAttestation));

  const onClose = () => {
    close();
    setBlob(undefined);
  };

  let widgetProps;
  if (theWidget && !showSign && esignWidget) {
    widgetProps = {
      zoneId,
      node: theNode,
      blob: theBlob,
      canRequest,
      canRespond,
      alreadyPackaged,
      pdfNotSupported,
      onDownloadOriginal: onDownloadOriginal,
      onClose: () => setWidget(() => WidgetNull),
    };
  } else if (theWidget && !showAttestation && attestionWidget) {
    widgetProps = {
      canAttestRequest: canAttestionRequest,
      canAttestRespond: canAttestionRespond,
      node: theNode,
      onClose: () => setWidget(() => WidgetNull),
    };
  }

  let ext, mime, type;

  if (theNode) {
    ext = getFileExtension(theNode.name);
    mime = getMimeTypeFromExt(ext);
    type = getTypeFromMime(mime);
  }

  const nav = [
    {
      type: "button",
      icon: "download",
      text: "Download",
      primary: true,
      onClick: () => onDownload(),
    },
    ...(onSign
      ? [
          {
            type: "button",
            icon: "esignature",
            text: "Signatures",
            warning: true,
            onClick: () => onSign(),
          },
        ]
      : []),
    ...(onAttest
      ? [
          {
            type: "button",
            icon: "endorsed",
            text: "Attestations",
            attestation: true,
            onClick: () => onAttest(),
          },
        ]
      : []),
    { type: "title" },
    { type: "expander" },
    //{
    //  type: "end",
    //},
    {
      type: "zoom",
    },
    {
      type: "nav",
    },
    {
      type: "button",
      icon: "cross",
      text: "Close",
      onClick: () => onClose(),
    },
  ];

  return (
    <Overlay
      title="Preview"
      isOpen={active}
      className={styles.overlay}
      hasBackdrop={false}
    >
      <Viewer
        blob={hasNode && theBlob}
        mime={mime}
        type={type}
        name={theNode?.name}
        Widget={!nullWidget && theWidget}
        widgetProps={widgetProps}
        nav={nav}
      />
    </Overlay>
  );
};
