import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import { Data, crypto } from "hyker-crypto";
import { uid, assign, request, json, jwtPeek } from "utils";
import createKeyperClient from "keyper";
import { KeyperClient } from "@keyper/specs";
import createApiClient from "api-client";
import * as config from "../../config";
import { requestKeyperAuthToken, getUserLogins } from "../../app/realworld.js";
import {
  ready,
  offline,
  login,
  initialWindowLocationHref,
} from "../../app/topSlice.js";
import { getToken } from "../../app/topSlice.js";
import { API_URL_PREFIX, API_URL_SUB } from "../../config";
import {
  UserHistory,
  User,
  AuthInfo,
  KeyperProps,
  Authentication,
} from "../../types";

interface LoginState {
  nonce: string;
  process: string;
  provider: string;
  progress: string;
  view: string;
  redirecting: boolean;
  showWaiting: boolean;
  identity: string[];
  verified: Record<string, boolean>;
  userId: string;
  orgId: string;
  token: string | null;
  credentials: CredentialsProps | null;
  publicTokenKeyper: string | null;
  authInfo: AuthInfo;
  userLogins: UserHistory[] | User["wellknown"];
  deviceId: string;
  status: string;
  hub: boolean;
  challenge: string;
}

interface CredentialsProps {
  id: string;
  org: string;
  device: string;
  account: string;
  credentials: string;
}

interface ProviderProps {
  provider: {
    type?: string;
    address?: string;
    phone_number?: string;
    swedish_personal_number?: string;
  };
}

const INIT_STATE: LoginState = {
  nonce: uid(),
  process: "LOGIN",
  provider: "",
  progress: "",
  view: "EMAIL",
  redirecting: false,
  showWaiting: true,
  identity: [],
  verified: {},
  userId: "",
  orgId: "",
  token: "",
  credentials: {
    id: "",
    org: "",
    device: "",
    account: "",
    credentials: "",
  },
  publicTokenKeyper: "",
  authInfo: {
    emails: [],
    phoneNumbers: [],
    swedishBankIds: [],
    saml: [],
  },
  userLogins: [],
  deviceId: "",
  status: "",
  hub: false,
  challenge: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    ...INIT_STATE,
    abort: false,
  } as LoginState & {
    abort: boolean;
  },
  reducers: {
    cancel: (state) => {
      assign(state, INIT_STATE, { nonce: uid(), abort: false });
      localStorage.clear();
    },
    setProcess: (state, { payload }: PayloadAction<string>) => {
      state.process = payload;
    },
    setProgress: (state, { payload }: PayloadAction<string>) => {
      state.progress = payload;
    },
    setProvider: (state, { payload }: PayloadAction<string>) => {
      state.provider = payload;
    },
    setRedirecting: (state, { payload }: PayloadAction<boolean>) => {
      state.redirecting = payload;
    },
    setUserId: (state, { payload }: PayloadAction<string>) => {
      state.userId = payload;
    },
    setOrgId: (state, { payload }: PayloadAction<string>) => {
      state.orgId = payload;
    },
    setIdentity: (state, { payload }: PayloadAction<string[]>) => {
      state.identity = payload;
    },
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      state.token = payload;
    },
    //FIXME: this is not used anywhere
    setVerified: (state, { payload }) => {
      assign(state.verified, { [payload]: true });
    },
    setCredentials: (state, { payload }: PayloadAction<CredentialsProps>) => {
      state.credentials = payload;
    },
    setShowWaiting: (state, { payload }: PayloadAction<boolean>) => {
      state.showWaiting = payload;
    },
    setAuthInfo: (state, { payload }: PayloadAction<AuthInfo>) => {
      state.authInfo = payload;
    },
    setUserLogins: (state, { payload }: PayloadAction<UserHistory[]>) => {
      state.userLogins = payload;
    },
    setDeviceId: (state, { payload }: PayloadAction<string>) => {
      state.deviceId = payload;
    },
    setView: (state, { payload }: PayloadAction<string>) => {
      state.view = payload;
    },
    setStatus: (state, { payload }: PayloadAction<string>) => {
      state.status = payload;
    },
    setAbort: (state, { payload }: PayloadAction<boolean>) => {
      state.abort = payload;
    },
    setHub: (state, { payload }: PayloadAction<boolean>) => {
      state.hub = payload;
    },
    setChallenge: (state, { payload }: PayloadAction<string>) => {
      state.challenge = payload;
    },
  },
});

export const {
  cancel,
  setProcess,
  setProgress,
  setProvider,
  setRedirecting,
  setIdentity,
  setToken,
  //FIXME: this is not used anywhere
  setVerified,
  setCredentials,
  setShowWaiting,
  setUserId,
  setOrgId,
  setAuthInfo,
  setUserLogins,
  setDeviceId,
  setView,
  setStatus,
  setAbort,
  setHub,
  setChallenge,
} = loginSlice.actions;

const getDeprecatedId = (provider: string, identity: string[]): string =>
  `${provider}:${identity.join("|")}`;

let _keyper: KeyperClient | undefined;

const keyper = async (): Promise<KeyperClient | undefined> => {
  let connection_try = 0;
  while (true) {
    if (!_keyper || !localStorage.getItem("keyperSession")) {
      try {
        _keyper = await createKeyperClient(config.KEYPER);
        const keyperAuthToken = await requestKeyperAuthToken();
        await _keyper.verifyToken(keyperAuthToken);
        {
          // SAVE CURRENT SESSION
          const keyperSession = await _keyper.getSession();
          localStorage.setItem("keyperSession", JSON.stringify(keyperSession));
        }
        break;
      } catch (e) {
        console.error("Keyper not available", e);
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            Math.max(500 * (1 + Math.pow(connection_try++, 2)), 5000)
          )
        );
      }
    } else if (_keyper.isClosing() || _keyper.isClosed()) {
      try {
        console.log("Reconnecting to keyper...");
        _keyper = await createKeyperClient(config.KEYPER);
        const keyperAuthToken = await requestKeyperAuthToken();
        await _keyper.verifyToken(keyperAuthToken);
        {
          // RESTORE OLD SESSION
          const keyperSession = localStorage.getItem("keyperSession");
          if (keyperSession) {
            console.log("Restoring keyper session...");
            await _keyper.restoreSession(JSON.parse(keyperSession));
          }
        }
        {
          // SAVE CURRENT SESSION
          const keyperSession = await _keyper.getSession();
          localStorage.setItem("keyperSession", JSON.stringify(keyperSession));
        }
        break;
      } catch (e) {
        console.error("Keyper not available", e);
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            Math.max(500 * (1 + Math.pow(connection_try++, 2)), 5000)
          )
        );
      }
    } else {
      break;
    }
  }
  return _keyper;
};

export const closeKeyper = () => async (dispatch: Dispatch) => {
  // REMOVE SESSION
  localStorage.removeItem("keyperSession");
  await (await keyper()).close();
};

export const sendEmailToKeyper =
  ({ email }: KeyperProps) =>
  async (dispatch: Dispatch) => {
    try {
      await (await keyper()).authenticateEmail(email);
    } catch (e) {
      Sentry.captureException(e);
      console.error("Keyper not available", e);
    }
  };

export const sendPhoneNumberToKeyper =
  ({ phoneNumber }: KeyperProps) =>
  async (dispatch: Dispatch) => {
    try {
      await (await keyper()).authenticateSms(phoneNumber);
    } catch (e) {
      Sentry.captureException(e);
      console.error("Keyper not available", e);
    }
  };

export const sendPinToKeyper =
  ({ email, phoneNumber, pin }: KeyperProps) =>
  async (dispatch: Dispatch) => {
    try {
      if (email) {
        await (await keyper()).unlockEmail({ address: email, code: pin });
      } else if (phoneNumber) {
        await (await keyper()).unlockSms({ phoneNumber, code: pin });
      }
      const { providers } = await (await keyper()).status();
      const { status } =
        providers.find(({ provider }: ProviderProps) => {
          if (email) {
            if (provider.type !== "email" || provider.address !== email) {
              return false;
            }
          }
          if (phoneNumber) {
            if (
              provider.type !== "sms" ||
              provider.phone_number !== phoneNumber
            ) {
              return false;
            }
          }
          return true;
        }) || {};
      if (!status || status.type !== "authenticated") {
        return { authenticated: false, publicToken: "" };
      }
      const publicToken = await dispatch(getPublicTokenFromKeyper());
      if (email) {
        const { authInfo, logins } = await getUserLogins(publicToken);
        dispatch(setAuthInfo(authInfo));
        dispatch(setUserLogins(logins));
      }
      return { authenticated: true, publicToken };
    } catch (e) {
      if (e.message === "incorrect_code") {
        return { authenticated: false, publicToken: "" };
      }
      Sentry.captureException(e);
      console.error("Keyper not available", e);
      // Throw keyper error to react components
      throw e;
    }
  };

export const startBankIdAuthentication =
  (direct: boolean) => async (dispatch: Dispatch, getState) => {
    try {
      dispatch(setProgress("WAITING"));
      if (direct) {
        dispatch(setView("BANKID_DIRECT"));
      }
      const provider = selectProvider(getState());
      const deviceId = selectDeviceId(getState());
      const view = selectView(getState());
      await (await keyper()).authenticateSwedishBankId();
      const pollForStatus = async () => {
        const { providers } = await (await keyper()).status();
        const authentication =
          providers.find(({ provider }: Authentication) => {
            if (provider.type === "unknown_swedish_bank_id") return true;
            if (provider.type === "swedish_bank_id") return true;
            return false;
          }) || {};
        if (authentication.status) {
          if (authentication.status.type === "authenticated") {
            const ssn = authentication.provider.swedish_personal_number;
            if (provider === "placeholder") {
              const deprecatedIdExists = await dispatch(
                doesDeprecatedIdExist(`bankid:${ssn}`)
              );
              if (deprecatedIdExists) {
                dispatch(setAbort(true));
                return;
              }
              await (
                await keyper()
              ).migrate({
                uid: deviceId,
                swedishPersonalNumbers: [ssn],
              });
              if (ssn) {
                await dispatch(setIdentity([ssn]));
                await dispatch(setProvider("bankid"));
              } else {
                throw new Error("Social Security Number (ssn) is undefined");
              }
              await dispatch(performLogin());
            } else if (provider === "bankid") {
              if (view === "BANKID_DIRECT") {
                await dispatch(setIdentity([ssn]));
                await dispatch(setStatus("authenticated"));
                await dispatch(setProvider("bankid"));
                const token = await dispatch(getPublicTokenFromKeyper());
                const { authInfo, logins } = await getUserLogins(token);
                dispatch(setAuthInfo(authInfo));
                dispatch(setUserLogins(logins));
                dispatch(setProgress(""));
                dispatch(setView("HUB"));
              } else {
                await dispatch(performLogin());
              }
            }
          } else if (authentication.status.type === "failed") {
            dispatch(setStatus(authentication.status.type));
            dispatch(setProgress("ERROR"));
          } else if (authentication.status.type === "pending") {
            dispatch(setChallenge(authentication.status.challenge));
            setTimeout(pollForStatus, 1000);
          } else {
            setTimeout(pollForStatus, 1000);
          }
        }
      };
      pollForStatus();
    } catch (e) {
      Sentry.captureException(e);
      console.error("Keyper not available", e);
    }
  };

export const getPublicTokenFromKeyper = () => async (dispatch: Dispatch) => {
  const publicToken = await (await keyper()).getPublicToken();
  return publicToken;
};

export const cancelKeyper = () => async (dispatch: Dispatch) => {
  await (await keyper()).cancel();
};

export const resetKeyper = () => async (dispatch: Dispatch) => {
  await (await keyper()).reset();
};

export const performLogin = () => async (dispatch: Dispatch, getState) => {
  const provider = selectProvider(getState());
  const identity = selectIdentity(getState());
  const deviceId = selectDeviceId(getState());
  const userId = selectUserId(getState());
  const orgId = selectOrgId(getState());
  dispatch(setProgress("WAITING"));
  try {
    const {
      identity: {
        ecdh_private_key: ecdhPrivateKeyDER,
        ecdsa_private_key: ecdsaPrivateKeyDER,
      },
      token,
    } = await (await keyper()).collect(deviceId);
    const privateECDHKey = await crypto.importPrivateECDHKey(
      Data.fromBase64(ecdhPrivateKeyDER),
      "pkcs1"
    );
    const privateECDSAKey = await crypto.importPrivateECDSAKey(
      Data.fromBase64(ecdsaPrivateKeyDER),
      "pkcs1"
    );
    await dispatch(
      setCredentials({
        id: userId,
        org: orgId,
        device: deviceId,
        account: getDeprecatedId(provider, identity),
        credentials: JSON.stringify({
          privateECDHKey: await privateECDHKey.export("jwk"),
          privateECDSAKey: await privateECDSAKey.export("jwk"),
          format: "jwk",
        }),
      })
    );
    await dispatch(finishLogin(token));
  } catch (error) {
    console.log(error);
  }
};

export const finishLogin =
  (token: LoginState["token"]) => async (dispatch: Dispatch, getState) => {
    try {
      const credentials = selectCredentials(getState());
      const abort = selectAbort(getState());

      if (abort) {
        await dispatch(resetKeyper());
        await dispatch(cancel());
        return;
      }

      dispatch(login(credentials));
      dispatch(persistSession(token));
      dispatch(setProgress("FINISHED"));
      await dispatch(closeKeyper());
    } catch (error) {
      console.log(error);
    }
  };

export const doesDeprecatedIdExist =
  (deprecatedId: string /* orgId: string */) =>
  async (dispatch: Dispatch, getState) => {
    const orgId = selectOrgId(getState());
    const api = createApiClient(config.API_URL_PREFIX);
    const result = await api
      .orgs(orgId)
      .users()
      .get({
        fields: {
          id: true,
          deprecatedId: true,
        },
        filter: [`deprecatedId[eq]${deprecatedId}`],
      });
    return result.length !== 0;
  };

export const persistSession =
  (token: LoginState["token"]) => async (dispatch: Dispatch, getState) => {
    const credentials = selectCredentials(getState());
    try {
      const sessionId = uid();
      const secret = await crypto.generateSecretKey();
      const stringSecret = (await secret.export(`raw`)).toBase64();

      const sessionToken = (
        await crypto.encrypt(Data.fromUTF8(JSON.stringify(credentials)), secret)
      ).toBase64();

      const url = `${API_URL_PREFIX}/sessions`;
      const accessToken = await dispatch(getToken());
      const { user: userId } = jwtPeek(accessToken);

      await request(
        url,
        json(
          {
            session: sessionId,
            secret: stringSecret,
            token,
          },
          {
            accessToken,
            userId,
          }
        )
      );
      localStorage.id = JSON.stringify({ sessionId, sessionToken });
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

export const restoreSession = () => async (dispatch: Dispatch) => {
  try {
    const url = new URL(initialWindowLocationHref);
    const searchParams = new URLSearchParams(url.search);
    try {
      if (url.pathname === "/saml/login") {
        dispatch(setProgress("WAITING"));
        const iv = Data.fromBase64URL(searchParams.get("iv"));
        const tag = Data.fromBase64URL(searchParams.get("tag"));
        const ciphertext = Data.fromBase64URL(searchParams.get("ciphertext"));
        const { subdomain } = JSON.parse(
          Data.fromBase64URL(searchParams.get("metadata")).toUTF8()
        );
        const isOnRootDomain = !API_URL_SUB;
        if (isOnRootDomain && subdomain) {
          const isValidSubdomain = /^[a-z0-9]+$/gi.test(subdomain);
          if (!isValidSubdomain) {
            throw new Error(`Bad subdomain: ${subdomain}`);
          }
          url.hostname = subdomain + "." + url.hostname;
          window.location.href = url.toString();
          return;
        }
        window.history.replaceState(null, "", "/");
        const ephemeralPublicECDHKey = await crypto.importPublicECDHKey(
          Data.fromBase64URL(searchParams.get("public_ecdh_key")),
          "spki"
        );
        const ephemeralPrivateECDHKey = await crypto.importPrivateECDHKey(
          Data.fromBase64(localStorage.getItem("saml")),
          "pkcs8"
        );
        const secretKey = await ephemeralPrivateECDHKey.agree(
          ephemeralPublicECDHKey
        );
        const rawTokenPack = await secretKey.decrypt(
          Data.join([ciphertext, tag]),
          iv
        );
        const { private_token: privateToken, public_token: publicToken } =
          JSON.parse(rawTokenPack.toUTF8());
        await (await keyper()).verifyToken(privateToken);
        const { authInfo, logins } = await getUserLogins(publicToken);
        if (logins.length === 0) {
          dispatch(setAuthInfo(authInfo));
          dispatch(setUserLogins(logins));
          dispatch(setProgress("ERROR"));
          throw new Error("No logins found");
        } else if (logins.length === 1) {
          const { device, org, provider, user, wellknown } = logins[0];
          await dispatch(setDeviceId(device));
          await dispatch(setOrgId(org));
          await dispatch(setUserId(user));
          await dispatch(setProvider(provider));
          await dispatch(setIdentity(wellknown));
          await dispatch(performLogin());
          dispatch(ready());
        } else {
          dispatch(setAuthInfo(authInfo));
          dispatch(setUserLogins(logins));
          dispatch(setView("HUB"));
        }
        return;
      }
    } catch (error) {
      dispatch(setView("SSO"));
    }

    if (!localStorage.id) {
      dispatch(ready());
      return;
    }

    let id = JSON.parse(localStorage.id);

    if ("sessionId" in id) {
      let secret;
      try {
        const url = `${API_URL_PREFIX}/sessions/${id.sessionId}`;
        const result = await request(url);
        secret = result.secret;
      } catch (e) {
        if (e == "SERVER_DENIED_PERMISSION") {
          throw e;
        } else {
          Sentry.captureException(e);
          dispatch(offline());
          return;
        }
      }
      id = JSON.parse(
        (
          await crypto.decrypt(
            Data.fromBase64(id.sessionToken),
            await crypto.importSecretKey(Data.fromBase64(secret), "raw")
          )
        ).toUTF8()
      );
    }

    if ("credentials" in id) {
      dispatch(login(id));
    } else {
      throw new Error(
        "could not produce credentials out of local storage identity"
      );
    }

    dispatch(ready());
  } catch (e) {
    if (e !== "SERVER_DENIED_PERMISSION") {
      Sentry.captureException(e);
      console.log("unknown error ", e);
    }
    localStorage.removeItem("id");
    window.location.href = window.location.href + "?timestamp=" + Date.now();
  }
};

//Create Interface
export const triggerSAML =
  (idProvider: string) => async (dispatch: Dispatch, getState) => {
    dispatch(setProgress("WAITING"));
    await dispatch(setRedirecting(true));
    const ecdhKeyPair = await crypto.generateECDHKeyPair();
    const privateKey = await ecdhKeyPair.privateKey.export("pkcs8");
    const publicKey = await ecdhKeyPair.publicKey.export("spki");
    localStorage.setItem("saml", privateKey.toBase64());
    const url = `https://${config.KEYPER.host}/saml/sso`;
    const idpURL = await (
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          issuer: config.KEYPER.issuer,
          id_provider: idProvider,
          public_ecdh_key: [...publicKey.getUint8Array()],
          metadata: [
            ...Data.fromUTF8(
              JSON.stringify({
                subdomain: API_URL_SUB || undefined,
              })
            ).getUint8Array(),
          ],
        }),
      })
    ).text();

    const abort = selectAbort(getState());
    if (abort) {
      return;
    }
    window.location.href = idpURL;
  };

export const selectNonce = (state) => state.login.nonce;

export const selectProcess = (state) => state.login.process;

export const selectProgress = (state) => state.login.progress;

export const selectProvider = (state) => state.login.provider;

export const selectUserId = (state) => state.login.userId;

export const selectOrgId = (state) => state.login.orgId;

export const selectIdentity = (state) => state.login.identity;

export const selectVerified = (state) => state.login.verified;

export const selectCredentials = (state) => state.login.credentials;

export const selectShowWaiting = (state) => state.login.showWaiting;

export const selectAuthInfo = (state) => state.login.authInfo;

export const selectUserLogins = (state) => state.login.userLogins;

export const selectDeviceId = (state) => state.login.deviceId;

export const selectView = (state) => state.login.view;

export const selectStatus = (state) => state.login.status;

export const selectAbort = (state) => state.login.abort;

export const selectHub = (state) => state.login.hub;

export const selectChallenge = (state) => state.login.challenge;

export default loginSlice.reducer;
