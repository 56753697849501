import React, { useState } from "react";

import { classNames } from "utils";

import styles from "./icons.module.css";

import reacticons from "./reacticons.module.css";

export const iconFactory = name => {
  const ext = (name || "").split(".").pop();
  switch (ext) {
    case "doc":
    case "docx":
      return <DocIcon text={ext} />;
    case "pdf":
      return <PdfIcon text={ext} />;
    case "ppt":
    case "pptx":
      return <SlideIcon text={ext} />;
    case "xls":
    case "xlsx":
      return <SheetIcon text={ext} />;
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return <ImageIcon text={ext} />;
    case "zip":
      return <ZipIcon text={ext} />;
    default:
      return <CodeIcon text={ext} />;
  }
};

export const FolderIcon = props => {
  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.folder,
          props.full && styles.full,
          props.open && styles.open
        )}
      >
        <div className={styles.tab} />
        <div className={styles.tag} />
        <div className={styles.lid} />
        {!props.special ? (
          <svg
            version="1.1"
            viewBox="5 15 20 20"
            className={classNames(styles.img)}
          >
            <g className={reacticons.reacticon__type}>
              <g>
                <path
                  className={reacticons["reacticon__text_row-3"]}
                  d="M16.5,25h-10C6.2,25,6,25.2,6,25.5v1C6,26.8,6.2,27,6.5,27h10c0.3,0,0.5-0.2,0.5-0.5v-1C17,25.2,16.8,25,16.5,25z"
                />
                <path
                  className={reacticons["reacticon__text_row-4"]}
                  d="M23.5,21h-17C6.2,21,6,21.2,6,21.5v1C6,22.8,6.2,23,6.5,23h17c0.3,0,0.5-0.2,0.5-0.5v-1C24,21.2,23.8,21,23.5,21z"
                />
              </g>
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={classNames(styles.img, styles.grp)}
          >
            <g className={reacticons.reacticon__type}>
              <path d="M0 0h24v24H0z" stroke="none" />
              <circle cx="9" cy="7" r="4" />
              <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 3.13a4 4 0 010 7.75M21 21v-2a4 4 0 00-3-3.85" />
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};

export const DocIcon = props => {
  //const [animate, setAnimate] = useState(false);
  const color = "#0074d9";
  const primaryColor = "#4D9EE4";
  const primaryColorLight = "#E6F1FB";
  const primaryColorMid = "#80BAEC";
  const primaryColorDark = "#1A82DD";
  const textColor = "#002E57";
  const borderColor = "#CCE3F7";

  const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className={reacticons.reacticon__type}>
            <g>
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-1"]}
                d="M23.5,13h-10c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.3,0.2,0.5,0.5,0.5h10c0.3,0,0.5-0.2,0.5-0.5v-1C24,13.2,23.8,13,23.5,13z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-2"]}
                d="M23.5,17h-10c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.3,0.2,0.5,0.5,0.5h10c0.3,0,0.5-0.2,0.5-0.5v-1C24,17.2,23.8,17,23.5,17z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-3"]}
                d="M16.5,25h-10C6.2,25,6,25.2,6,25.5v1C6,26.8,6.2,27,6.5,27h10c0.3,0,0.5-0.2,0.5-0.5v-1C17,25.2,16.8,25,16.5,25z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-4"]}
                d="M23.5,21h-17C6.2,21,6,21.2,6,21.5v1C6,22.8,6.2,23,6.5,23h17c0.3,0,0.5-0.2,0.5-0.5v-1C24,21.2,23.8,21,23.5,21z"
              />
            </g>
            <g>
              <path
                fill={primaryColorLight}
                className={reacticons.reacticon__image_bg}
                d="M7,19h4c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1H7c-0.6,0-1,0.4-1,1v4C6,18.6,6.4,19,7,19z"
              />
              <path
                fill={primaryColorMid}
                className={reacticons["reacticon__image_rear-mtn"]}
                d="M6.5,18.9l2.2-2.2L8.1,16c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2L6,17.1V18C6,18.4,6.2,18.7,6.5,18.9z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__image_front-mtn"]}
                d="M11.2,15.4c-0.2-0.2-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2l-3.5,3.5C6.7,18.9,6.8,19,7,19h4c0,0,0,0,0.1,0c0.5,0,0.9-0.5,0.9-1v-1.8L11.2,15.4z"
              />
              <path
                fill={primaryColorMid}
                className={reacticons.reacticon__image_sun}
                d="M6.5,14.5c0.6,0,1-0.4,1-1c0-0.2-0.1-0.4-0.1-0.5H7c-0.5,0-1,0.4-1,1v0.4C6.1,14.4,6.3,14.5,6.5,14.5z"
              />
            </g>
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: 4 - text.length + "px"
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export const PdfIcon = props => {
  //const [animate, setAnimate] = useState(false);
  const color = "#d90000";
  const primaryColor = "#e44d4d";
  const primaryColorLight = "#fbe6e6";
  const primaryColorMid = "#ec8080";
  const primaryColorDark = "#dd1a1a";
  const textColor = "#570000";
  const borderColor = "#f7cccc";

  const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className={reacticons.reacticon__type}>
            <g>
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-1"]}
                d="M23.5,13h-10c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.3,0.2,0.5,0.5,0.5h10c0.3,0,0.5-0.2,0.5-0.5v-1C24,13.2,23.8,13,23.5,13z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-2"]}
                d="M23.5,17h-10c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.3,0.2,0.5,0.5,0.5h10c0.3,0,0.5-0.2,0.5-0.5v-1C24,17.2,23.8,17,23.5,17z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-3"]}
                d="M16.5,25h-10C6.2,25,6,25.2,6,25.5v1C6,26.8,6.2,27,6.5,27h10c0.3,0,0.5-0.2,0.5-0.5v-1C17,25.2,16.8,25,16.5,25z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__text_row-4"]}
                d="M23.5,21h-17C6.2,21,6,21.2,6,21.5v1C6,22.8,6.2,23,6.5,23h17c0.3,0,0.5-0.2,0.5-0.5v-1C24,21.2,23.8,21,23.5,21z"
              />
            </g>
            <g>
              <path
                fill={primaryColorLight}
                className={reacticons.reacticon__image_bg}
                d="M7,19h4c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1H7c-0.6,0-1,0.4-1,1v4C6,18.6,6.4,19,7,19z"
              />
              <path
                fill={primaryColorMid}
                className={reacticons["reacticon__image_rear-mtn"]}
                d="M6.5,18.9l2.2-2.2L8.1,16c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2L6,17.1V18C6,18.4,6.2,18.7,6.5,18.9z"
              />
              <path
                fill={primaryColor}
                className={reacticons["reacticon__image_front-mtn"]}
                d="M11.2,15.4c-0.2-0.2-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2l-3.5,3.5C6.7,18.9,6.8,19,7,19h4c0,0,0,0,0.1,0c0.5,0,0.9-0.5,0.9-1v-1.8L11.2,15.4z"
              />
              <path
                fill={primaryColorMid}
                className={reacticons.reacticon__image_sun}
                d="M6.5,14.5c0.6,0,1-0.4,1-1c0-0.2-0.1-0.4-0.1-0.5H7c-0.5,0-1,0.4-1,1v0.4C6.1,14.4,6.3,14.5,6.5,14.5z"
              />
            </g>
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: 4 - text.length + "px"
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export const SlideIcon = props => {
  //const [animate, setAnimate] = useState(false);
  const color = "#FF5722";
  const primaryColor = "#FF8964";
  const primaryColorLight = "#FFEEE9";
  const primaryColorMid = "#FFAB91";
  const primaryColorDark = "#FF6838";
  const textColor = "#66230E";
  const borderColor = "#FFDDD3";

  const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className="reacticon__type">
            <path
              fill={primaryColorDark}
              className="reacticon__slides_bg"
              d="M23,13H7c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V14C24,13.4,23.6,13,23,13L23,13z"
            />
            <g>
              <path
                fill={primaryColorMid}
                className="reacticon__slides_pie"
                d="M14.9,16.1c-2.5,0-4.4,2-4.4,4.4c0,2.5,2,4.4,4.4,4.4s4.4-2,4.4-4.4h-4.4V16.1z"
              />
              <path
                fill={primaryColorLight}
                className="reacticon__slides_slice"
                d="M16.1,15v4.4h4.4C20.5,17,18.5,15,16.1,15z"
              />
            </g>
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: 4 - text.length + "px"
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export const SheetIcon = props => {
  //const [animate, setAnimate] = useState(false);
  const color = "#25A333";
  const primaryColor = "#66BF70";
  const primaryColorLight = "#E9F6EB";
  const primaryColorMid = "#92D199";
  const primaryColorDark = "#3BAC47";
  const textColor = "#0F4114";
  const borderColor = "#D3EDD6";

  const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className="reacticon__type">
            <path
              fill={primaryColorDark}
              className="reacticon__table_row-7"
              d="M24,15v-1c0-0.6-0.4-1-1-1H7c-0.6,0-1,0.4-1,1v1H24z"
            />
            <rect
              fill={primaryColorLight}
              className="reacticon__table_row-6"
              x="6"
              y="15"
              width="18"
              height="2"
            />
            <rect
              fill={primaryColorMid}
              className="reacticon__table_row-5"
              x="6"
              y="17"
              width="18"
              height="2"
            />
            <rect
              fill={primaryColorLight}
              className="reacticon__table_row-4"
              x="6"
              y="19"
              width="18"
              height="2"
            />
            <rect
              fill={primaryColorMid}
              className="reacticon__table_row-3"
              x="6"
              y="21"
              width="18"
              height="2"
            />
            <rect
              fill={primaryColorLight}
              className="reacticon__table_row-2"
              x="6"
              y="23"
              width="18"
              height="2"
            />
            <path
              fill={primaryColorMid}
              className="reacticon__table_row-1"
              d="M23,27H7c-0.6,0-1-0.4-1-1v-1h18v1C24,26.6,23.6,27,23,27z"
            />
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: 4 - text.length + "px"
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export const ImageIcon = props => {
  //const [animate, setAnimate] = useState(false);
  const color = "#9C27B0";
  const primaryColor = "#BA68C8";
  const primaryColorLight = "#F5E9F7";
  const primaryColorMid = "#CE93D8";
  const primaryColorDark = "#A63DB8";
  const textColor = "#3E1046";
  const borderColor = "#EBD4EF";

  const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className="reacticon__type">
            <path
              fill={primaryColorLight}
              className="reacticon__image_bg"
              d="M23,13H7c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V14C24,13.4,23.6,13,23,13L23,13z"
            />
            <path
              fill={primaryColorDark}
              className="reacticon__image_rear-mtn"
              d="M7.1,27l15.3,0l-7.5-7.5c-0.3-0.3-0.8-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5L6,25.9V26C6,26.6,6.5,27,7.1,27z"
            />
            <path
              fill={primaryColor}
              className="reacticon__image_front-mtn"
              d="M24,19l-1.1-1c-0.4-0.4-1-0.6-1.5-0.6c-0.5,0-1.1,0.2-1.5,0.6l-9.1,9H23c0.6,0,1-0.4,1-1V19z"
            />
            <path
              fill={primaryColorMid}
              className="reacticon__image_sun"
              d="M7,13c-0.6,0-1,0.4-1,1v2.2c0.3,0.2,0.7,0.3,1.2,0.3c1.3,0,2.3-1,2.3-2.3c0-0.4-0.1-0.8-0.3-1.2H7z"
            />
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: 4 - text.length + "px"
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export const CodeIcon = props => {
  //const [animate, setAnimate] = useState(false);
  const color = "#3F51B5";
  const primaryColor = "#7985CB";
  const primaryColorLight = "#ECEEF8";
  const primaryColorMid = "#9FA8DA";
  const primaryColorDark = "#5262BC";
  const textColor = "#192048";
  const borderColor = "#D9DCF0";

  const whiteColor = "#ffffff";

  const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className="reacticon__type">
            <path
              fill={primaryColorDark}
              className="reacticon__code_sidebar"
              d="M9,26V14v-1H7c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1h2V26z"
            />
            <g>
              <path
                fill={primaryColorLight}
                className="reacticon__code_row-7"
                d="M24,15H9v-2h14c0.6,0,1,0.4,1,1V15z"
              />
              <rect
                fill={primaryColorMid}
                className="reacticon__code_row-6"
                x="9"
                y="15"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorLight}
                className="reacticon__code_row-5"
                x="9"
                y="17"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorMid}
                className="reacticon__code_row-5"
                x="9"
                y="19"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorLight}
                className="reacticon__code_row-3"
                x="9"
                y="21"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorMid}
                className="reacticon__code_row-2"
                x="9"
                y="23"
                width="15"
                height="2"
              />
              <path
                fill={primaryColorLight}
                className="reacticon__code_row-1"
                d="M23,27H9v-2h15v1C24,26.6,23.6,27,23,27z"
              />
            </g>
            <g className="reacticon__code_numbers">
              <path
                fill={whiteColor}
                d="M8.1,15.9v0.3H7v-0.3h0.4V15H7v-0.2c0.2,0,0.3-0.1,0.4-0.1h0.3v1.3H8.1z"
              />
              <path
                fill={whiteColor}
                d="M6.9,19c0.5-0.4,0.8-0.7,0.8-0.9c0-0.2-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.2l-0.2-0.2c0.2-0.2,0.3-0.3,0.6-0.3c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.3,0.6-0.6,0.8c0.1,0,0.2,0,0.3,0h0.4v0.3H6.9V19z"
              />
              <path
                fill={whiteColor}
                d="M7,21.8C7.1,21.9,7.3,22,7.4,22c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.5-0.2v-0.3c0.3,0,0.4-0.1,0.4-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.1l-0.2-0.2c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0,0.6,0.2,0.6,0.4c0,0.2-0.1,0.3-0.3,0.4v0c0.2,0.1,0.4,0.2,0.4,0.4c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.5-0.1-0.6-0.2L7,21.8z"
              />
              <path
                fill={whiteColor}
                d="M8.1,24.9H7.9v0.4H7.6v-0.4H6.8v-0.3l0.7-1h0.4v0.9h0.2V24.9z M7.6,24.6v-0.3c0-0.1,0-0.2,0-0.3h0c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.3H7.6z"
              />
            </g>
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: 4 - text.length + "px"
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export const ZipIcon = props => {
  const primaryColorLight = "#FFECB3";
  const primaryColorMid = "#FFC107";
  const primaryColorDark = "#FF8F00";
  const textColor = "black";
  const borderColor = "transparent";
  const whiteColor = "#ffffff";

  //const [animate, setAnimate] = useState(false);
  //const text = (props.text || "").slice(0, 4);

  return (
    <div className={styles.icon}>
      <div
        className={classNames(
          styles.doc /*, animate && reacticons["animate"]*/
        )}
        //onClick={() => setAnimate(!animate)}
        style={{
          background: primaryColorLight,
          borderColor: borderColor
        }}
      >
        <svg version="1.1" viewBox="2 8 26 20">
          <g className="reacticon__type">
            <path
              fill={primaryColorDark}
              className="reacticon__code_sidebar"
              d="M9,26V14v-1H7c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1h2V26z"
            />
            <g>
              <path
                fill={primaryColorLight}
                className="reacticon__code_row-7"
                d="M24,15H9v-2h14c0.6,0,1,0.4,1,1V15z"
              />
              <rect
                fill={primaryColorMid}
                className="reacticon__code_row-6"
                x="9"
                y="15"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorLight}
                className="reacticon__code_row-5"
                x="9"
                y="17"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorMid}
                className="reacticon__code_row-5"
                x="9"
                y="19"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorLight}
                className="reacticon__code_row-3"
                x="9"
                y="21"
                width="15"
                height="2"
              />
              <rect
                fill={primaryColorMid}
                className="reacticon__code_row-2"
                x="9"
                y="23"
                width="15"
                height="2"
              />
              <path
                fill={primaryColorLight}
                className="reacticon__code_row-1"
                d="M23,27H9v-2h15v1C24,26.6,23.6,27,23,27z"
              />
            </g>
            <g className="reacticon__code_numbers">
              <path
                fill={whiteColor}
                d="M8.1,15.9v0.3H7v-0.3h0.4V15H7v-0.2c0.2,0,0.3-0.1,0.4-0.1h0.3v1.3H8.1z"
              />
              <path
                fill={whiteColor}
                d="M6.9,19c0.5-0.4,0.8-0.7,0.8-0.9c0-0.2-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.2l-0.2-0.2c0.2-0.2,0.3-0.3,0.6-0.3c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.3,0.6-0.6,0.8c0.1,0,0.2,0,0.3,0h0.4v0.3H6.9V19z"
              />
              <path
                fill={whiteColor}
                d="M7,21.8C7.1,21.9,7.3,22,7.4,22c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.5-0.2v-0.3c0.3,0,0.4-0.1,0.4-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.1l-0.2-0.2c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0,0.6,0.2,0.6,0.4c0,0.2-0.1,0.3-0.3,0.4v0c0.2,0.1,0.4,0.2,0.4,0.4c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.5-0.1-0.6-0.2L7,21.8z"
              />
              <path
                fill={whiteColor}
                d="M8.1,24.9H7.9v0.4H7.6v-0.4H6.8v-0.3l0.7-1h0.4v0.9h0.2V24.9z M7.6,24.6v-0.3c0-0.1,0-0.2,0-0.3h0c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.3H7.6z"
              />
            </g>
          </g>
        </svg>
        <p
          style={{
            color: textColor,
            letterSpacing: "1px"
          }}
        >
          zip
        </p>
      </div>
    </div>
  );
};
