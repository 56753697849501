import React, { useRef, useCallback, useEffect } from "react";

import { Icon } from "@blueprintjs/core";

import EsignatureIcon from "../esignature/esignatureIcon";

import { Skeleton } from "./Skeleton";

import MozillaViewer from "./Mozilla";

import { createBackend as createPDFBackend } from "./pdfBackend";
import { createBackend as createDocBackend } from "./docBackend";
import { createBackend as createSheetBackend } from "./sheetBackend";
import { createBackend as createSlideBackend } from "./slideBackend";

import {
  fromFile,
  toBase64,
  toChecksum,
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
} from "./utils";

import styles from "./Viewer.module.css";

export {
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
};

const createDefaultBackend = () => { };

const chooseBackend = (type) => {
  let createBackend;
  if (type === "pdf") {
    createBackend = createPDFBackend;
  } else if (type === "doc") {
    createBackend = createDocBackend;
  } else if (type === "sheet") {
    createBackend = createSheetBackend;
  } else if (type === "slide") {
    createBackend = createSlideBackend;
  } else {
    createBackend = createDefaultBackend;
  }
  return createBackend;
};

export const fromBlob = async (blob) => {
  const mime = blob?.type || "";
  const type = getTypeFromMime(mime);
  const bytes = await fromFile(blob);
  const checksum = "";//await toBase64(await toChecksum(bytes));
  return [type, bytes, checksum, mime];
};

export const Viewer = ({
  type,
  blob,
  mime,
  name,
  Widget = null,
  widgetProps = {},
  nav = []
}) => {
  const refElement = useRef(null);
  const refBackend = useRef(null);
  const refPrev = useRef(null);

  const setRef = useCallback((element) => {
    if (refBackend.current) {
      refBackend.current.destroy();
    }
    refElement.current = element;
    if (element && blob) {
      refBackend.current = chooseBackend(type)(element, blob, mime);
    }
  }, [blob]);

  useEffect(() => {
    if (!blob) {
      if (refBackend.current) {
        refBackend.current.destroy();
      }
    } else if (refPrev.current && refPrev.current !== blob) {
      refPrev.current = blob;
      if (refElement.current) {
        if (refBackend.current) {
          refBackend.current.destroy();
        }
        refBackend.current = chooseBackend(type)(refElement.current, blob, mime);
      }
    }
  }, [blob]);

  let content;

  if (blob) {
    for (const item of nav) {
      if (item.icon === "esignature") {
        item.icon = <EsignatureIcon />;
      } else if (item.icon === "endorsed") {
        item.icon = <Icon icon={item.icon} />;
      } else if (item.type === "zoom") {
        item.onIn = () => refBackend.current.zoomIn();
        item.onOut = () => refBackend.current.zoomOut();
      } else if (item.type === "nav") {
        item.onPrev = () => refBackend.current.navPrev();
        item.onNext = () => refBackend.current.navNext();
      } else if (item.type === "end") {
        item.onEnd = () => refBackend.current.navEnd();
      }
    }
    if (type === "pdf") {
      content =
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "nav")}
          Widget={Widget}
          widgetProps={widgetProps}
        >
          <PdfViewer setRef={setRef} />
        </ViewerBody>;
    } else if (type === "doc") {
      content =
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "nav" && item.type !== "end")}
          Widget={Widget}
          widgetProps={widgetProps}
        >
          <DocViewer setRef={setRef} />
        </ViewerBody>;
    } else if (type === "sheet") {
      content =
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "end")}
          Widget={Widget}
          widgetProps={widgetProps}
        >
          <SheetViewer setRef={setRef} />
        </ViewerBody>;
    } else if (type === "slide") {
      content =
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "end")}
          Widget={Widget}
          widgetProps={widgetProps}
        >
          <SlideViewer setRef={setRef} />
        </ViewerBody>;
    } else {
      content =
        <ViewerBody>
          <AViewer />
        </ViewerBody>;
    }
  } else {
    content = <Skeleton />;
  }

  return content;
};

const ViewerBody = ({
  name,
  backend,
  children,
  nav = [],
  Widget = null,
  widgetProps = {},
}) => {
  let row = [];
  for (const item of nav) {
    if (item.type === "expander") {
      row.push(<div className={styles.Expander} />);
    } else if (item.type === "title") {
      row.push(
        <div className={styles.Title}>
          {name}
        </div>
      );
    } else if (item.type === "button") {
      let className = styles.Button;
      if (item.primary) {
        className += " " + styles.Primary;
      } else if (item.warning) {
        className += " " + styles.Warning;
      } else if (item.attestation) {
        className += " " + styles.Attestation;
      }
      row.push(
        <div
          className={className}
          onClick={() => item.onClick?.()}
        >
          {typeof item.icon === "string" ? <Icon icon={item.icon} /> : item.icon}
          <div>{item.text}</div>
        </div>
      );
    } else if (item.type === "zoom") {
      row.push(
        <div className={styles.Nav + " " + styles.Zoom}>
          <div onClick={() => item.onOut?.()}>
            <Icon icon="minus" />
          </div>
          <div onClick={() => item.onIn?.()}>
            <Icon icon="plus" />
          </div>
        </div>
      );
    } else if (item.type === "nav") {
      row.push(
        <div className={styles.Nav}>
          <div onClick={() => item.onPrev?.()}>
            <Icon icon="chevron-left" />
          </div>
          <div onClick={() => item.onNext?.()}>
            <Icon icon="chevron-right" />
          </div>
        </div>
      );
    } else if (item.type === "end") {
      row.push(
        <div
          className={styles.Button}
          onClick={() => item.onEnd?.()}
        >
          <Icon icon="flow-end" />
        </div>
      );
    }
  }
  row = row.map((row, i) => React.cloneElement(row, { key: i }));
  return (
    <div className={styles.Outer}>
      <div className={styles.Navbar}>
        {row}
      </div>
      <div className={styles.Inner}>
        {Widget && (
          <>
            <input id="1UzhThFd" type="checkbox" className={styles.Toggle} />
            <label htmlFor="1UzhThFd">
              <Icon icon="arrow-left" />
              <Icon icon="menu" />
              <div></div>
            </label>
            <div className={styles.Sidebar}>
              <div onClick={() => widgetProps?.onClose?.()}>
                <Icon icon="small-cross" />
                <div>close</div>
              </div>
              <Widget
                goToLastPage={() => backend?.navEnd?.()}
                {...widgetProps}
              />
            </div>
          </>
        )}
        <div className={styles.Main}>
          {children}
        </div>
      </div>
    </div>
  );
};

const AViewer = () => <div className={styles.AViewer} />;

const PdfViewer = ({ setRef }) =>
  <MozillaViewer>
    <div ref={setRef} />
  </MozillaViewer>;

const DocViewer = ({ setRef }) =>
  <div
    ref={setRef}
    className={styles.NSon100p}
  />;

const SheetViewer = ({ setRef }) =>
  <div
    ref={setRef}
    className={styles.NSon100p}
  />;

const SlideViewer = ({ setRef }) =>
  <div
    ref={setRef}
    className={styles.NSon100p}
  />;
