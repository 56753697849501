import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setDialog } from "../../app/appSlice.js";
import { TREE_ACTIONS } from "../dialogs/Dialogs.js";
import { ARG_TIME, ARG_DATE, ARG_DATE_NO_YEAR } from "../chat/derive.js";
import {
  FILE_CREATE,
  FILE_DOWNLOAD,
  FILE_PREVIEW,
  FOLDER_CREATE,
  ESIGNATURE_REQUEST,
  ESIGNATURE_APPROVE,
  ESIGNATURE_REJECT,
} from "../../app/permissions.js";
import Size from "./Size";
import {
  uid,
  classNames,
  assign,
  pick,
  throttle,
  debounce,
  keys,
  values,
} from "utils";
import {
  Button,
  Classes,
  Intent,
  Icon,
  Dialog,
  H5,
  Tag,
} from "@blueprintjs/core";
import { selectIdentity } from "../../app/topSlice.js";
import { /*expandNode,*/ setOverlay, approve } from "../../app/appSlice.js";
import appContext from "../../app/appContext.js";
import { Summary } from "../transfer/Transfer.js";
import {
  startUpload,
  startDownload,
  selectSummary,
} from "../transfer/transferSlice.js";
import styles from "./Tree.module.css";
import {
  iconFactory,
  FolderIcon,
  SlideIcon,
  SheetIcon,
  ImageIcon,
  DocIcon,
  CodeIcon,
} from "../../helpers/tree-icons";
import { mobile } from "../../helpers/detect-browser";
import { createDropzone, createHoverZone, getDraggedDom } from "./helpers.js";
import {
  TREE_BACKGROUND,
  TREE_FOLDER,
  TREE_FILE,
} from "../contexts/Contexts.js";
import { useContextMenu } from "../contexts/Popover.js";
import { appState } from "../../app/appState.js";
import { dispatchToast } from "../toasts/Toasts.js";
import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays.js";
import { isPreviewableFileType } from "../overlays/Preview.js";
import { PANE_SEARCH, togglePane } from "../layout/layoutSlice.js";

const {
  derive: { getTree, getGroupsFromCurrentZone },
  selectors: { selectGraph, selectNodes, selectPermissions },
  actions: { setCurrentNode, setExpandedNode, moveNode },
  actions: { expandNode },
} = appState;

const getAttestationStatus = (node) => {
  if (!node.storageId) {
    return 3;
  }
  if (!node.attestations) {
    return 2;
  }
  if (!node.attestations[0]) {
    return 1;
  }
  if (!node.attestations[0].approvals[0]) {
    return -2;
  }
  if (!node.attestations[0].rejections[0]) {
    return -1;
  }
  return 0;
};

const getEsignatureStatus = (node) => {
  if (!node.storageId) {
    return 3;
  }
  if (!node.esignatures) {
    return 2;
  }
  if (!node.esignatures[0]) {
    return 1;
  }
  if (!node.esignatures[0].approvals[0]) {
    return -2;
  }
  if (!node.esignatures[0].rejections[0]) {
    return -1;
  }
  return 0;
};

const ORDER_OF_SORTS = {
  date: (a, b) => {
    const v = a.timestamp - b.timestamp;
    return v != 0 ? v : a.index - b.index;
  },
  name: (a, b) => {
    const v = a.name.localeCompare(b.name);
    return v != 0 ? v : a.index - b.index;
  },
  attest: (a, b) => {
    const aAttestationStatus = getAttestationStatus(a);
    const bAttestationStatus = getAttestationStatus(b);

    const v = aAttestationStatus - bAttestationStatus;
    return v != 0 ? v : a.index - b.index;
  },
  esign: (a, b) => {
    const aEsignatureStatus = getEsignatureStatus(a);
    const bEsignatureStatus = getEsignatureStatus(b);

    const v = aEsignatureStatus - bEsignatureStatus;
    return v != 0 ? v : a.index - b.index;
  },
  size: (a, b) => {
    const aSize = a.meta && "size" in a.meta ? a.meta.size : -1;
    const bSize = b.meta && "size" in b.meta ? b.meta.size : -1;
    const v = aSize - bSize;
    return v != 0 ? v : a.index - b.index;
  },
  index: (a, b) => {
    return a.index - b.index;
  },
};

const DEFAULT_SORT = "index";

const portal = document.createElement("div");
portal.dataset.dndPortal = "tree";
document.body.appendChild(portal);

export const Tree = React.memo(({ ani, realEstateBudget }) => {
  const dispatch = useDispatch();
  const summary = useSelector(selectSummary);
  const permissions = useSelector(selectPermissions);
  const [sortOn, _setSortOn] = useState(DEFAULT_SORT);
  const [reverseSort, _setReverseSort] = useState(false);

  const setSortOn = (sortIndex) => {
    if (sortIndex != sortOn) {
      _setReverseSort(false);
      _setSortOn(sortIndex);
    } else if (!reverseSort) {
      _setReverseSort(true);
    } else {
      _setSortOn(DEFAULT_SORT);
      _setReverseSort(false);
    }
  };

  const {
    node,
    zoneName,
    zoneId,
    groupId,
    folderId,
    rootNodeId,
    rootGroupId,
    isRestricted,
  } = useContext(appContext);

  const graph = useSelector(selectGraph);
  const nodes = useSelector(selectNodes);
  const tree = useMemo(() => getTree(graph), [graph]);
  const [dropzone, setDropzone] = useState(false);
  const [destination, setDestination] = useState(null);
  const [theDropZoneEl, setDropZoneEl] = useState();
  const [showFooter, setShowFooter] = useState(false);
  const volatileNodes = useRef();
  volatileNodes.current = nodes;

  const groups = getGroupsFromCurrentZone(graph);
  const zone = graph.zones[zoneId];

  let hasRootAccess = false;
  if (zone) {
    hasRootAccess = groups.some((grp) => grp.id === zone.virtualRootGroup);
  }

  const premature =
    !zoneId || !(FILE_CREATE in permissions) || !(FILE_DOWNLOAD in permissions);
  const noAccess =
    (isRestricted && groups.length == 1 && groups[0].id == rootGroupId) ||
    //(FILE_CREATE in permissions && !permissions[FILE_CREATE]) ||
    //(FILE_DOWNLOAD in permissions && !permissions[FILE_DOWNLOAD]);
    (!permissions[FILE_CREATE] &&
      !permissions[FILE_DOWNLOAD] &&
      !permissions[FILE_PREVIEW]);

  useEffect(() => {
    setShowFooter(false);
  }, [zoneId]);

  useEffect(() => {
    setShowFooter(dropzone || summary);
  }, [dropzone, summary]);

  useEffect(() => {
    if (!theDropZoneEl || !zoneId || ani || premature || noAccess) {
      return;
    }

    const [theFreeZoneEl] = theDropZoneEl.getElementsByClassName(styles.footer);

    const onStart = (dragging) => {
      setDropzone(dragging);
    };

    const onHover = (id, el, els) => {
      const { current: nodes } = volatileNodes;
      for (const el of els) {
        el.classList.remove(styles.isDropping);
      }
      const isCombinable = el && "combinable" in el.dataset;

      if (isCombinable) {
        setDestination(nodes[id]);
        el.classList.add(styles.isDropping);
        theDropZoneEl.classList.add(styles.dropzoneOpa);
      } else {
        setDestination(null);
        theDropZoneEl.classList.remove(styles.dropzoneOpa);
      }
    };

    const onDrop = (id, e) => {
      const { current: nodes } = volatileNodes;
      while (nodes[id]?.storageId) {
        id = nodes[id]?.parent;
      }
      if (!id) {
        if (!hasRootAccess) return;
        id = rootNodeId;
      }
      const node = nodes[id];
      const folderId = node.id;
      const groupId = node.group;


      const itemsContainFolders = Array.from(e.dataTransfer.items).some((item) => {
        try {
          const entry = item.webkitGetAsEntry();
          const isDirectory = entry ? entry.isDirectory : false;

          if (item.kind === 'string') {
            dispatchToast(dispatch, {
              message: "Invalid file or folder. Try right-clicking and select 'Upload'.",
              icon: "folder",
              intent: Intent.DANGER,
            });
            return false;
          }

          return isDirectory;
        } catch (error) {
          console.error('Error processing item:', error);
          return false;
        }
      });

      const canCreateFolder = permissions[FOLDER_CREATE];
      if (itemsContainFolders && !canCreateFolder) {
        dispatchToast(dispatch, {
          message: "You have no permission to create folders.",
          icon: "folder",
          intent: Intent.DANGER,
        });
      } else {
        if (values(e.dataTransfer.types).includes("Files")) {
          dispatch(
            startUpload(e.dataTransfer.items, groupId, folderId, zoneId)
          );
        }
      }
      e.preventDefault();
    };

    return createDropzone(
      theDropZoneEl,
      theFreeZoneEl,
      onStart,
      onHover,
      onDrop
    );
  }, [zoneId, theDropZoneEl, ani, premature, noAccess]);

  const catchDropZoneEl = useCallback((el) => setDropZoneEl(el), []);

  if (ani || premature || noAccess) {
    return <Splash noAccess={!premature && noAccess} />;
  }

  const ctx = hasRootAccess ? TREE_BACKGROUND : null;

  const hasAttestations = tree.some((item) => item.attestations?.length);
  const hasEsignatures = tree.some((item) => item.esignatures?.length);

  if (hasAttestations) {
    realEstateBudget--;
  }

  return (
    <div
      ref={catchDropZoneEl}
      className={classNames(
        styles.outer,
        dropzone && styles.dropzone,
        !hasRootAccess && styles.red
      )}
      //style={{ "--columns" : hasAttestations ? 4 : 3 }}
      data-context={ctx}
      onClick={() => dispatch(setCurrentNode())}
      onContextMenu={() => dispatch(setCurrentNode())}
    >
      <div className={styles.tree}>
        <div className={styles.bar}>
          <div onClick={(e) => setSortOn("name")} className={styles.header}>
            <span>Docs</span>
            {sortOn === "name" && (
              <Icon
                className={classNames(reverseSort && styles.upsideDown)}
                icon="caret-down"
              />
            )}
          </div>
          {realEstateBudget >= 1 && (
            <div onClick={(e) => setSortOn("size")} className={styles.header}>
              <span>Size</span>
              {sortOn === "size" && (
                <Icon
                  className={classNames(reverseSort && styles.upsideDown)}
                  icon="caret-down"
                />
              )}
            </div>
          )}
          {hasAttestations /* && !hasEsignatures */ && (
            <div
              onClick={(e) => setSortOn("attest")}
              className={classNames(styles.header, styles.narrow)}
            >
              <span>Attest</span>
              {sortOn === "attest" && (
                <Icon
                  className={classNames(reverseSort && styles.upsideDown)}
                  icon="caret-down"
                />
              )}
            </div>
          )}
          {hasEsignatures && (
            <div
              onClick={(e) => setSortOn("esign")}
              className={classNames(styles.header, styles.narrow)}
            >
              <span>Sign</span>
              {sortOn === "esign" && (
                <Icon
                  className={classNames(reverseSort && styles.upsideDown)}
                  icon="caret-down"
                />
              )}
            </div>
          )}
          {realEstateBudget >= 0 && (
            <div onClick={(e) => setSortOn("date")} className={styles.header}>
              <span>Date</span>
              {sortOn === "date" && (
                <Icon
                  className={classNames(reverseSort && styles.upsideDown)}
                  icon="caret-down"
                />
              )}
            </div>
          )}
          <div
            className={styles.header}
            onClick={() => dispatch(togglePane(PANE_SEARCH))}
          >
            <Icon icon="search" />
          </div>
        </div>
        <div
          className={classNames(
            styles.scroll,
            showFooter && styles.scrollWithFooter,
            mobile && styles.scrollWithOptions
          )}
        >
          <DNDList
            realEstateBudget={realEstateBudget}
            hasRootAccess={hasRootAccess}
            hasAttestations={hasAttestations}
            hasEsignatures={hasEsignatures}
            sortOn={sortOn}
            reverse={reverseSort}
            list={tree}
            graph={graph}
            permissions={permissions}
          />
        </div>
        {mobile && (
          <div
            className={styles.options}
            onClick={(e) => {
              dispatch(setDialog({ dialog: TREE_ACTIONS }));
            }}
          >
            <Icon icon="more" />
            <span>Options</span>
          </div>
        )}
        <div
          //ref={freezoneEl}
          className={classNames(
            styles.footer,
            showFooter && styles.footerEnabled
          )}
        >
          {dropzone ? (
            <>
              <div className={classNames(styles.uploadImage)}>
                <Icon icon="cloud-upload" />
                <Icon icon="shield" />
                <Icon icon="clean" />
              </div>
              <div className={styles.beforeDrop}>
                <div className={styles.dropToUpload}>
                  {hasRootAccess ? "Drop to upload" : "You dont have access"}
                </div>
                <div>{(destination && destination.name) || zoneName}</div>
              </div>
            </>
          ) : (
            <div>
              <Summary />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const DNDList = ({
  realEstateBudget,
  hasRootAccess,
  hasAttestations,
  hasEsignatures,
  list,
  sortOn,
  reverse,
  graph,
  permissions,
}) => {
  const dispatch = useDispatch();
  const [isCombineEnabled, setIsCombineEnabled] = useState(true);
  const [activeDraggableId, setActiveDraggableId] = useState(false);
  const { zoneId, rootNodeId } = useContext(appContext);

  const listEl = useRef(null);
  const dragEl = useRef(null);
  const placeholderEl = useRef(null);
  const semaphore = useRef(false);
  const indentation = useRef(0);
  const mouseLongitude = useRef(0);

  useEffect(() => {
    const onMouseMove = (e) => (mouseLongitude.current = e.clientX);
    document.addEventListener("mousemove", onMouseMove, true);
    return () => document.removeEventListener("mousemove", onMouseMove, true);
  }, []);

  useEffect(() => {
    const onChange = () => {
      if (document.getElementsByClassName(styles.dropping).length) {
        document.body.classList.add(styles.isCombining);
      } else {
        document.body.classList.remove(styles.isCombining);
      }
    };
    const observer = new MutationObserver(throttle(onChange, 50));
    observer.observe(listEl.current, {
      subtree: true,
      childList: true,
      attributes: true,
    });
    return () => observer.disconnect();
  }, []);

  const expandTimeout = useRef();

  useEffect(() => {
    const onHover = (id, hover) => {
      clearTimeout(expandTimeout.current);
      if (hover) {
        expandTimeout.current = setTimeout(() => {
          document.body.classList.add("no-tree-row-ani");
          dispatch(setExpandedNode({ id, expanded: true }));
          setTimeout(() => {
            document.body.classList.remove("no-tree-row-ani");
          }, 500);
        }, 1500);
        const isCombinable = "combinable" in hover.dataset;
        setIsCombineEnabled(isCombinable);
      } else {
        setIsCombineEnabled(true);
      }
    };
    const destroy = createHoverZone(listEl.current, onHover);
    return () => {
      clearTimeout(expandTimeout.current);
      destroy();
    };
  }, []);

  const handleDragEnd = (result) => {
    clearTimeout(expandTimeout.current);

    semaphore.current = false;
    indentation.current = 0;
    dragEl.current.style.transition = null;
    dragEl.current.style.transform = null;
    dragEl.current = null;

    if (placeholderEl.current) {
      placeholderEl.current.classList.remove(styles.around);
    }

    setActiveDraggableId("");

    const { draggableId: nodeId, combine, source, destination } = result;

    if (source.index == destination?.index) {
      return;
    }

    //let index = -1,
    let index = undefined,
      parentId = rootNodeId;

    if (combine) {
      //index = 0;
      parentId = combine.draggableId;
      const parent = getDraggedDom(parentId);
      if (!("combinable" in parent.dataset)) {
        return;
      }
    } else if (destination) {
      const myList = [...list];
      const from = myList[source.index];
      myList.splice(source.index, 1);
      if (destination.index < myList.length) {
        const target = myList[destination.index];
        parentId = target.parent;
        index = target.index;
      }
    } else {
      return;
    }

    if (!hasRootAccess && !parentId) {
      return dispatch(approve("You don't have access"));
    }

    if (
      graph.nodes[graph.nodes[nodeId].parent].group ==
      graph.nodes[parentId].group
    ) {
      dispatch(moveNode({ zoneId, nodeId, parentId, index }));
    } else {
      const text = [
        "Are you sure?",
        "You might be about to change who can access this resource.",
      ];
      dispatch(approve(text)).then(({ approved }) => {
        if (approved) {
          dispatch(moveNode({ zoneId, nodeId, parentId, index }));
        }
      });
    }
  };

  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    dragEl.current = draggedDOM.firstElementChild;

    setActiveDraggableId(event.draggableId);
  };

  const handleDragUpdate = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    if (!event.destination) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    if (false && destinationIndex == sourceIndex) {
      if (placeholderEl.current) {
        placeholderEl.current.classList.remove(styles.around);
      }
      return;
    }

    //const childrenArray = [...draggedDOM.parentNode.children];
    //const movedItem = childrenArray[sourceIndex];
    //childrenArray.splice(sourceIndex, 1);

    //const updatedArray = [
    //  ...childrenArray.slice(0, destinationIndex),
    //  movedItem,
    //  ...childrenArray.slice(destinationIndex + 1)
    //];

    if (listEl.current) {
      const childrenArray = [
        ...listEl.current.firstElementChild.firstElementChild.children,
      ];
      const box = listEl.current.getBoundingClientRect();

      const clientY =
        box.top +
        childrenArray.slice(0, destinationIndex).reduce((total, curr) => {
          return total + curr.clientHeight;
        }, 0);

      const depth = parseFloat(
        childrenArray[destinationIndex]?.firstElementChild?.firstElementChild
          ?.firstElementChild?.firstElementChild?.style?.width || "0"
      );

      const clientX = box.left + depth;

      if (placeholderEl.current) {
        placeholderEl.current.classList.remove(styles.around);
        placeholderEl.current.style.top = clientY + "px";
        placeholderEl.current.style.left = clientX + "px";
        setTimeout(
          () => placeholderEl.current.classList.add(styles.around),
          100
        );
      }

      indentation.current = depth;
    }
  };

  let hideKidsOf = activeDraggableId ? [activeDraggableId] : [];

  //const getRootPath = node => {
  //  const parent = list.find(n => node.parent == n.id);
  //  if (parent) {
  //    return [...getRootPath(parent), node];
  //  } else {
  //    return [{ id: "fake_root", name: "" }, node];
  //  }
  //};

  //const getRootPath = (node, l = [...list]) => {
  //  const index = l.findIndex(n => node.parent == n.id);
  //  if (index != -1) {
  //    return [...getRootPath(l.splice(index, 1)[0], l), node];
  //  } else {
  //    return [{ id: "fake_root", name: "" }, node];
  //  }
  //};

  //const map = {};

  //for (const node of list) {
  //  if (node.parent in map) {
  //    map[node.parent].push(node);
  //  } else {
  //    map[node.parent] = [ node ];
  //  }
  //}

  const getRootPath = (node) => {
    const path = [node];
    while (node.parent && node.parent in graph.nodes) {
      node = graph.nodes[node.parent];
      path.unshift(node);
    }
    path.unshift({ id: "fake_root", name: "" });
    return path;
  };

  //let t0 = new Date;
  list = [...list].sort((a, b) => {
    const aP = getRootPath(a);
    const bP = getRootPath(b);

    let i = 0;
    for (; i < Math.min(aP.length, bP.length); i++) {
      if (aP[i].id != bP[i].id) {
        i++;
        break;
      }
    }
    i--;

    return ORDER_OF_SORTS[sortOn](aP[i], bP[i]) * (reverse ? -1 : 1);
  });
  //console.log("list sort took", new Date - t0);

  //let t1 = new Date;
  list = list.filter((item) => {
    if (hideKidsOf.includes(item.parent)) {
      hideKidsOf.push(item.id);
      return false;
    }
    return true;
  });
  //console.log("list filter took", new Date - t1);

  return (
    <div ref={listEl}>
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        <Droppable droppableId="tree" isCombineEnabled={isCombineEnabled}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              data-droppable="tree"
              className={classNames(
                activeDraggableId
                  ? styles.isDraggingOver
                  : styles.isDraggingOverNot
              )}
            >
              {/*<TransitionGroup>*/}
              {list.map((item, index) => (
                /*{<CSSTransition
                    key={item.id}
                    classNames="tree-row-ani"
                    timeout={200}
                  >}*/
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={mobile || !item.parent}
                >
                  {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                      if (dragEl.current && !semaphore.current) {
                        semaphore.current = true;
                        const kidEl = dragEl.current.firstElementChild;
                        const { right } = kidEl.getBoundingClientRect();
                        if (right < mouseLongitude.current) {
                          const diff = mouseLongitude.current - right + 50;
                          dragEl.current.style.transition = `transform 250ms`;
                          dragEl.current.style.transform = `translate(${diff}px, 0)`;
                        }
                      }
                      if (dragEl.current && snapshot.isDropAnimating) {
                        dragEl.current.style.transition = `transform 200ms`;
                        dragEl.current.style.transform = null;
                        if (indentation.current) {
                          const diff = indentation.current;
                          dragEl.current.style.transform = `translate(${diff}px, 0)`;
                          indentation.current = 0;
                        }
                      }
                    }
                    const child = (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        {...{
                          ["data-rbd-drag-handle-draggable-id"]: item.id,
                        }}
                        {...(!item.storageId
                          ? { ["data-combinable"]: "yes" }
                          : {})}
                        {...(snapshot.isDragging
                          ? { ["data-dragging"]: "yes" }
                          : {})}
                        className={classNames(
                          styles.node,
                          styles.draggable,
                          snapshot.isDragging && styles.dragging,
                          snapshot.combineTargetFor && styles.dropping,
                          item.storageId ? styles.file : styles.folder
                        )}
                      >
                        <Item
                          realEstateBudget={realEstateBudget}
                          hasAttestations={hasAttestations}
                          hasEsignatures={hasEsignatures}
                          item={item}
                          permissions={permissions}
                          zoneId={zoneId}
                        />
                      </div>
                    );

                    if (snapshot.isDragging) {
                      return ReactDOM.createPortal(child, portal);
                    }
                    return child;
                  }}
                </Draggable>
                /*{</CSSTransition>}*/
              ))}
              {provided.placeholder}
              {/*</TransitionGroup>*/}
              {ReactDOM.createPortal(
                <div
                  ref={placeholderEl}
                  className={classNames(styles.placeholder)}
                />,
                portal
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export const Item = ({
  realEstateBudget,
  hasAttestations,
  hasEsignatures,
  item,
  permissions,
  zoneId,
}) => {
  const refFile = useContextMenu(TREE_FILE, { nodeId: item.id });
  const refFolder = useContextMenu(TREE_FOLDER, { nodeId: item.id });
  const { id: me } = useSelector(selectIdentity);
  const dispatch = useDispatch();
  const isFile = !!item.storageId;
  const { id, current } = item;

  const timestamp = useRef(0);
  const timeout = useRef(null);
  //const DOUBLE_CLICK_MS = 500;
  const DOUBLE_CLICK_MS = 1000;

  const onClick = useCallback(
    (ref) => {
      if (mobile) {
        onDoubleClick(item);
      } else if (item.current) {
        clearTimeout(timeout.current);
        if (new Date() - timestamp.current < DOUBLE_CLICK_MS) {
          onDoubleClick(item);
        } else {
          timestamp.current = new Date();
          timeout.current = setTimeout(() => {
            dispatch(setCurrentNode(null));
            onSingleClick(item);
          }, DOUBLE_CLICK_MS);
        }
      } else {
        timestamp.current = new Date();
        dispatch(setCurrentNode(id));
        onSingleClick(item);
      }
    },
    [item]
  );

  const onSingleClick = async (item) => { };

  const onDoubleClick = async (item) => {
    if (item.storageId) {
      const allowedToDownload = permissions[FILE_DOWNLOAD];
      const allowedToPreview = permissions[FILE_PREVIEW];
      const isPreviewable = isPreviewableFileType(item);
      const preferePreview =
        permissions[ESIGNATURE_REQUEST] ||
        permissions[ESIGNATURE_APPROVE] ||
        permissions[ESIGNATURE_REJECT];
      if (mobile || preferePreview) {
        if (isPreviewable && (allowedToPreview || allowedToDownload)) {
          dispatch(
            setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId })
          );
        } else if (allowedToDownload) {
          dispatch(startDownload(item));
        }
      } else {
        if (allowedToDownload) {
          dispatch(startDownload(item));
        } else if (isPreviewable && allowedToPreview) {
          dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item }));
        }
      }
    } else {
      dispatch(expandNode({ id: item.id, expanded: !item.expanded }));
    }
  };

  let icon = (
    <FolderIcon
      full={!item.empty}
      open={item.expanded}
      special={item.restricted}
    />
  );

  if (isFile) {
    icon = iconFactory(item.name);
  }

  let dateString,
    dateObject = new Date(item.timestamp);

  if (
    new Date().setHours(0, 0, 0, 0) ==
    new Date(item.timestamp).setHours(0, 0, 0, 0)
  ) {
    dateString = dateObject.toLocaleTimeString(...ARG_TIME);
  } else if (dateObject.getFullYear() == new Date().getFullYear()) {
    dateString = new Date(item.timestamp).toLocaleDateString(
      ...ARG_DATE_NO_YEAR
    );
  } else {
    dateString = new Date(item.timestamp).toLocaleDateString(...ARG_DATE);
  }

  const attest = item.attestations && !!item.attestations.length;
  const reject = attest && !!item.attestations[0].rejections.length;
  const approve =
    attest &&
    item.attestations[0].approvals.length ===
    item.attestations[0].signees.length;
  const expired = Date.parse(item.attestations[0]?.deadline) < Date.now();

  const signable =
    attest &&
    !reject &&
    !approve &&
    !expired &&
    item.attestations[0].signees /*.map(({ id }) => id)*/
      .includes(me);

  let attestIcon = "";

  if (expired) {
    attestIcon = "ban-circle";
  } else if (signable) {
    attestIcon = "notifications";
  } else if (reject || approve) {
    attestIcon = "endorsed";
  } else {
    attestIcon = "updated";
  }

  const attestStyle = classNames(
    styles.noneAttest,
    expired && styles.reject,
    attest && styles.attest,
    reject && styles.reject,
    approve && styles.approve,
    signable && styles.signable
  );

  const sign = item.esignatures && !!item.esignatures.length;
  const expiredSignicat = sign && item.esignatures[0].status === "expired";
  const canceled = sign && !!item.esignatures[0].rejections.length;
  const signed = sign && item.esignatures[0].status === "signed";
  const esignable =
    sign && !canceled && !signed && item.esignatures[0].signees.includes(me);

  const expiredKonfident =
    sign &&
    new Date(item?.esignatures[0]?.deadline).getTime() < Date.now() &&
    !item?.esignatures[0]?.completed &&
    !canceled;

  let esignatureIcon = "";

  if (expiredKonfident || expiredSignicat) {
    esignatureIcon = "ban-circle";
  } else if (canceled) {
    esignatureIcon = "ban-circle";
  } else if (signed) {
    esignatureIcon = "tick-circle";
  } else if (esignable) {
    esignatureIcon = "notifications";
  } else {
    esignatureIcon = "updated";
  }

  const esignatureStyle = classNames(
    styles.noEsignature,
    sign && styles.esignature,
    (expiredKonfident || expiredSignicat || canceled) && styles.canceled,
    signed && styles.signed,
    esignable && styles.signable
  );

  return (
    <div
      ref={isFile ? refFile : refFolder}
      className={classNames(styles.wrapper, item.current && styles.current)}
      onContextMenu={(e) => {
        e.stopPropagation();
        if (!item.current) {
          dispatch(setCurrentNode(item.id));
        }
      }}
      //style={{ cursor: "pointer" }}
      onClick={(e) => e.stopPropagation() | onClick()}
    >
      <div
        className={classNames(styles.row, item.current && styles.currentRow)}
      >
        <div className={styles.item}>
          <div style={{ width: (item.depth ? item.depth * 20 : 0) + "px" }} />
          <div className={styles.hoverholder}>
            {icon}
            <span>{item.name}</span>
          </div>
        </div>
        {realEstateBudget >= 1 && (
          <div className={styles.item}>
            <span>
              {item.meta && "size" in item.meta ? (
                <Size bytes={item.meta.size} />
              ) : (
                ""
              )}
            </span>
          </div>
        )}
        {hasAttestations /* && !hasEsignatures */ && (
          <div className={classNames(styles.item, styles.narrow)}>
            <span />
            <Icon className={attestStyle} icon={attestIcon} />
          </div>
        )}
        {hasEsignatures && (
          <div className={classNames(styles.item, styles.narrow)}>
            <span />
            <Icon className={esignatureStyle} icon={esignatureIcon} />
          </div>
        )}
        {realEstateBudget >= 0 && (
          <div className={styles.item}>
            <span>{dateString}</span>
          </div>
        )}
        {mobile && !isFile ? (
          <div
            className={classNames(styles.item, styles.more)}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setDialog({ dialog: TREE_ACTIONS, item }));
            }}
          >
            <Icon icon="more" iconSize={20} />
          </div>
        ) : (
          <div className={styles.item} />
        )}
      </div>
    </div>
  );
};

export const Splash = ({ noAccess }) => {
  return (
    <div className={styles.splash}>
      <div className={styles.bar}>
        <div className={styles.header}>
          <span>Docs</span>
        </div>
        <div className={styles.header} />
      </div>
      <div className={styles.rest}>
        {noAccess && (
          <div className={styles.empty}>
            <div>
              <Icon iconSize={44} icon="eye-off" />
            </div>
            <div>
              <div>Nothing To See Here</div>
              <div>You've got no access to selected resources</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
