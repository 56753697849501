//import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";
import { Dialog, Intent, Icon } from "@blueprintjs/core";
import { classNames } from "utils";
import { selectIdentity, inBullhorn } from "../../app/topSlice.js";
import RolePicker from "../roles/RolePicker.js";
import { useRoles } from "../../app/rolesContext.js";
import styles from "./index.module.css";
import { sendInvite } from "./inviteSlice";
import Bankid from "./bankid";
import Twofa from "./2fa";
import Email from "./email";
import SSO, { useIdProviders } from "./sso";
import {
  SSO as SSOIcon,
  BankID as BankIDIcon,
  Email as EmailIcon,
  TwoFA as TwoFAIcon,
  SelectProvider as SelectProviderIcon,
} from "../login/icons";
import { dispatchToast } from "../toasts/Toasts.js";
import { appState } from "../../app/appState.js";
import { TEAM_TEAMMATE_ADD } from "../../app/permissions.js";

const {
  actions: { createUser, addMember },
  selectors: { selectPermissions },
} = appState;

const roleCompare = (a, b) => a.privilegeOrder - b.privilegeOrder;

export default function Invite({ active, close, zoneId, zoneName }) {
  const DEFAULT_PROVIDER = inBullhorn ? "2fa" : "select";

  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const [inviteeRole, setInviteeRole] = useState();
  const [provider, setProvider] = useState(DEFAULT_PROVIDER);
  const [state, _setState] = useState({});
  const roles = useRoles();
  const permissions = useSelector(selectPermissions);
  const [disableInviteButton, setDisableInviteButton] = useState(false);
  const idProviders = useIdProviders();

  const doneFetchingRoles = !!roles;

  useEffect(() => {
    if (!doneFetchingRoles || inviteeRole) {
      return;
    }
    const role = roles
      .filter((role) => role.allowedToSet)
      .sort(roleCompare)
      .reverse()[0];
    setInviteeRole(role && role.id);
  }, [doneFetchingRoles, inviteeRole, roles]);

  useEffect(() => {
    if (!active) {
      _setState({});
      setInviteeRole(null);
      setProvider(DEFAULT_PROVIDER);
      setDisableInviteButton(false);
    }
  }, [active]);

  if (!identity) {
    return null;
  }

  const setState = (next) => _setState({ ...state, ...next });

  const { moreOptions, doNotAddToZone } = state;
  const { emailOk, numberOk, notifyOk, ssnOk, idProviderOk } = state;

  const submit = async () => {
    try {
      setDisableInviteButton(true);
      let myProvider = provider.toLowerCase();
      let { notifyAddress: email, wellknown, extraAuthenticatorArgs } = state;
      wellknown = wellknown && wellknown.map((s) => s.replace(/\s/g, ""));
      if (!email && wellknown) {
        if (myProvider === "saml") {
          [, email] = wellknown;
        } else {
          [email] = wellknown;
        }
      }
      const error =
        (!emailOk && myProvider !== "bankid") ||
        (!numberOk && myProvider === "2fa") ||
        (!notifyOk && (moreOptions || myProvider === "bankid")) ||
        (!ssnOk && moreOptions && myProvider === "bankid") ||
        (!emailOk && myProvider === "saml") ||
        (!idProviderOk && myProvider === "saml");

      if (error) {
        setState({ showErrors: true });
        return;
      }

      if (myProvider === "bankid" && !moreOptions) {
        myProvider = "placeholder";
        wellknown = undefined;
        extraAuthenticatorArgs = {
          allowed_providers: ["bankid"],
        };
      }

      const roleId = inviteeRole;
      if (!roleId || !(typeof roleId === "string")) {
        dispatchToast(dispatch, {
          message: "Bad user role...",
          icon: "people",
          intent: Intent.DANGER,
          timeout: 0,
        });
        return;
      }

      const toast = dispatchToast(dispatch, {
        message: "Inviting User",
        icon: "SPINNER",
        intent: Intent.PRIMARY,
        timeout: 0,
      });

      try {
        const { userId } = await dispatch(
          createUser({
            extraAuthenticatorArgs,
            provider: myProvider,
            wellknown,
            email,
            roleId,
            name,
          })
        );

        await dispatch(sendInvite({ userId }));

        if (!doNotAddToZone && zoneId && permissions[TEAM_TEAMMATE_ADD]) {
          const { reason } = await dispatch(
            addMember({
              zoneId,
              userId,
            })
          );

          if (reason) {
            if (reason !== "PRESENT") {
              if (reason !== "HANDLED") {
                toast.replace({
                  message: "Something went wrong, please try again.",
                  icon: "warning-sign",
                  intent: Intent.DANGER,
                });
              } else {
                toast.dismiss();
              }
              return;
            }
          }
        }

        toast.replace({
          message: "User Invited",
          icon: "CHECK",
          intent: Intent.PRIMARY,
        });
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      }

      close();
    } finally {
      setDisableInviteButton(false);
    }
  };

  const showEmail = !inBullhorn;
  const show2FA = true;
  const showBankID = !inBullhorn;

  const props = { state, setState, submit };

  const PROVIDERS = [
    {
      id: "select",
      label: "Select Provider",
      icon: SelectProviderIcon,
      extra: null,
    },
  ];

  if (showEmail) {
    PROVIDERS.push({
      id: "email",
      label: "Email",
      icon: EmailIcon,
      extra: <Email {...props} />,
    });
  }
  
  if (show2FA) {
    PROVIDERS.push({
      id: "2fa",
      label: "2FA",
      icon: TwoFAIcon,
      extra: <Twofa {...props} />
    });
  }

  if (showBankID) {
    PROVIDERS.push({
      id: "bankid",
      label: "BankID",
      icon: BankIDIcon,
      extra: <Bankid {...props} />,
    });
  }

  if (Object.keys(idProviders).length > 0) {
    PROVIDERS.push({
      id: "saml",
      label: "SSO",
      icon: SSOIcon,
      extra: <SSO {...props} idProviders={idProviders} />,
    });
  }

  const {
    icon: ProviderIcon,
    extra,
    name,
  } = PROVIDERS.find(({ id }) => id === provider);

  const changeProvider = (provider) => {
    _setState({});
    setProvider(provider);
  };

  const inviteButton = provider !== "select" && <button
    className={styles.dialogButton}
    onClick={() => submit()}
    disabled={disableInviteButton}
  >
    Invite
  </button>;

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <div className={styles.title}>
          <ProviderIcon size={34} />
          <span className={styles.dialogTitle}>Invite A New User</span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.outer}>
        <div className={classNames("select", styles.select)}>
          <select onChange={(e) => changeProvider(e.target.value)}>
            {PROVIDERS.map(({ id, label }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className={styles.midgard}>
            {extra}
            {permissions[TEAM_TEAMMATE_ADD] && zoneId && (
              <>
                {provider !== "select" && <label
                  className={classNames("checkbox", styles.checkbox)}
                  title="Check the box if you want to add the new user to this team."
                >
                  <div>
                    <Icon iconSize={14} icon="user" />
                  </div>
                  <div>
                    <Icon iconSize={14} icon="plus" />
                  </div>
                  <div>
                    <Icon iconSize={14} icon="application" />
                  </div>
                  <span>{zoneName}</span>
                  <input
                    type="checkbox"
                    checked={!doNotAddToZone}
                    onChange={(e) =>
                      setState({ doNotAddToZone: !e.target.checked })
                    }
                  />
                  <span className={"checkmark"} />
                </label>}
              </>
            )}
            <div className={styles.br} style={{ height: "50px" }} />
            {extra && (
              <RolePicker onSelect={setInviteeRole} currentRole={inviteeRole} />
            )}
          </div>
        </div>
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        {inviteButton}
      </div>
    </Dialog>
  );
}
